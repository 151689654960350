import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import Auth from './pages/auth';
import ErrorPage from './pages/error-page';
import AuthProvider from './components/AuthProvider';
import supabase from './api/supabase';
import AuthRequired from './pages/auth-required';
import Landing from './pages/landing';
import flowScreenshotLoader from './pages/flow-screenshot/loader';
import FlowScreenshot from './pages/flow-screenshot';
import { PaymentSucceeded } from './pages/payment/succeed';

const router = createBrowserRouter([
  {
    id: 'tools',
    path: '/tools',
    errorElement: <ErrorPage />,
    element: <AuthRequired />,
    children: [
      {
        index: true,
        path: '/tools/:toolId',
        loader: flowScreenshotLoader as any,
        Component: FlowScreenshot,
      },
    ],
  },
  {
    id: 'root',
    path: '/',
    index: true,
    Component: Landing
  },
  {
    path: '/payment/success',
    Component: PaymentSucceeded,
    async loader() {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return redirect('/');
    },
  },
  {
    path: '/auth',
    Component: Auth
  },
  {
    path: '/auth/logout',
    async action() {
      await supabase.auth.signOut();
      return redirect('/');
    },
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider supabase={supabase}>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
