import { Response } from "../../api/response";
import { resolveAPIBase } from "../../api/settings";
import { FlowScreenshotInput, FlowScreenshotOutput } from "./models";

export class FlowScreenshotAPI {
  private apiBase: string;

  constructor() {
    this.apiBase = resolveAPIBase("flow-screenshot", "v1");
  }

  async openai(
    input: FlowScreenshotInput
  ): Promise<Response<FlowScreenshotOutput>> {
    try {
      const httpResp = await fetch(`${this.apiBase}/openai`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const resp = await httpResp.json();
      console.log(resp);

      return resp;
    } catch (e) {
      console.error("Failed to parse response", e);

      return {
        error: {
          message: `Failed to parse response`,
        },
      };
    }
  }
}
