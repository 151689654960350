import { ChangeEvent, useEffect, useRef } from "react";
import { useComponentStateOp, useComponentStateValue } from "./atom";
import { LoadedImage } from "../atom";

function StateLoading() {
  return (
    <div>
      <span className="loading loading-ring loading-xs"></span>
    </div>
  );
}

type StateWaitingVideoFileProps = {
  onClick: () => void;
};

function StateWaitingVideoFile(props: StateWaitingVideoFileProps) {
  const { onClick } = props;

  return (
    <>
      <div className="flex-1 py-[4px] flex items-center gap-[8px]"
        onClick={onClick}
      >
        <p className='text-primary-content font-medium text-[12px]'>
          🗃️
        </p>
        <p className='text-primary-content font-medium text-[12px]'>
          Select a video
        </p>
      </div>
    </>
  );
}


type StateVideoFileLoadedProps = {
  onClick: () => void;
  videoName: string;
  frameImages: LoadedImage[];
};


function StateVideoFileLoaded(props: StateVideoFileLoadedProps) {
  const { onClick, videoName, frameImages } = props;

  const framesWord = frameImages.length === 1 ? "key frame" : "key frames";

  return (
    <>
      <div className="flex-1 py-[4px] flex items-center gap-[8px]"
        onClick={onClick}
      >
        <p className='text-primary-content font-medium text-[12px]'>
          🎬
        </p>
        <p className='text-primary-content font-medium text-[12px]'>
          {videoName}
        </p>
        <p className="text-secondary-content font-regular text-[12px]">
          ·
        </p>
        <p className='text-secondary-content font-regular text-[12px]'>
          {frameImages.length} {framesWord}
        </p>
      </div>
    </>
  );
}

export type VideoInputProps = {
  onLoadedImages: (images: LoadedImage[]) => void;
};

export default function VideoInput(props: VideoInputProps) {
  const { onLoadedImages } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const componentState = useComponentStateValue();
  const op = useComponentStateOp();

  useEffect(() => {
    op.tick();
  }, [op]);

  return (
    <div className='flex items-center gap-[8px] px-[1rem]'>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept="video/*"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (!event.target.files || event.target.files.length < 1) {
            return;
          }

          const videoFile = event.target.files[0];
          op.processVideo(videoFile).then(images => {
            if (images) {
              onLoadedImages(images);
            }
          })
        }}
      />
      {componentState.state === "init" && (<StateLoading />)}
      {componentState.state === "ffmpegLoading" && (<StateLoading />)}
      {componentState.state === "fileParsing" && (<StateLoading />)}
      {componentState.state === "ffmpegReady" && (
        <StateWaitingVideoFile onClick={() => {
          fileInputRef.current?.click();
        }}
        />
      )}
      {componentState.state === "fileParsed" && (
        <StateVideoFileLoaded
          onClick={() => {
            fileInputRef.current?.click();
          }}
          videoName={componentState.inputFile?.name ?? "video.mp4"}
          frameImages={componentState.frameImages}
        />
      )}
    </div>
  );
} 