import { IconHistory, IconNumber } from "@tabler/icons-react";
import classNames from "classnames";
import { useState } from "react";
import { Tool } from "../../app/models";
import { Link, useFetcher } from "react-router-dom";

type ToolSelectionProps = {
  tools: Tool[];
  selectedToolId: string | null;
}

type PageSideNavProps = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
} & ToolSelectionProps;

function PageSideNav(props: PageSideNavProps) {
  const { visible, onVisibleChange, tools, selectedToolId } = props;
  const fetcher = useFetcher();

  const csContainer = classNames(
    'fixed', 'top-0', 'left-0',
    'h-[100vh]', 'w-[100vw]',
    'z-[--z-index-menu]', 'flex', 'transition-transform', 'duration-300',
    {
      'translate-x-0': visible,
      '-translate-x-full': !visible,
    }
  );

  const toolItems = tools.map(tool => {
    const cs = classNames({
      'text-primary-content': tool.id === selectedToolId,
    })

    return (
      <Link
        key={tool.id}
        className={cs}
        to={`/tools/${tool.id}`}
        onClick={() => {
          onVisibleChange(false);
        }}
      >
        {tool.displayName}
      </Link>
    );
  });

  return (
    <div className={csContainer}>
      <div className="w-[--side-menu-width] max-w-[--side-menu-width] h-full bg-base-200 drop-shadow-sm">
        <div className="flex h-[--nav-header-height] items-center px-[1rem] text-[16px]">
          <div className="bg-center bg-cover bg-logo192 h-[28px] w-[28px] mr-2"></div>
          <h1 className="font-bold text-primary-content">
            Unfriendly Tools
          </h1>
        </div>
        <div className="flex flex-col items-leading px-[1rem] font-regular text-[14px] text-base-content gap-[8px]">
          {toolItems}
          <span className="py-2 min-h-1"></span>
          <p>More tools...</p>
        </div>
        <div className="mt-12 flex flex-col items-leading px-[1rem] font-regular text-[14px] text-base-content gap-[8px]">
          <h3 className="font-medium text-[10px]">My Stuffs</h3>
          <p className="flex items-center gap-1">
            <IconNumber
              size="14px"
            />
            Usage Credit
          </p>
          <p className="flex items-center gap-1">
            <IconHistory
              size="14px"
            />
            History
          </p>
          <span className="py-2 min-h-1"></span>
          <fetcher.Form method="post" action="/auth/logout">
            <button type="submit" disabled={fetcher.formData != null}>Sign Out</button>
          </fetcher.Form>
        </div>
      </div>
      <div className="h-full flex-1" onClick={() => {
        onVisibleChange(false);
      }}></div>
    </div>
  );
}

export type PageNavProps = {
  title: string;
} & ToolSelectionProps;

export default function PageNav(props: PageNavProps) {
  const [showSideNav, setShowSideNav] = useState(false);
  const { title, ...toolSelectionProps } = props;

  return (
    <>
      <div className="navbar h-[--nav-header-height] text-primary-content p-0 fixed top-0 z-[--z-index-menu] bg-primary drop-shadow-sm">
        <div className="flex-none w-[30px]">
          <button className="btn btn-square btn-ghost outline-none"
            onClick={() => setShowSideNav(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block h-5 w-5 stroke-current">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        <div className="flex-1">
          <button className="btn btn-ghost no-animation text-{12px} text-left font-bold outline-none"
            onClick={() => setShowSideNav(true)}
          >
            {title}
          </button>
        </div>
      </div>
      <PageSideNav
        visible={showSideNav}
        onVisibleChange={setShowSideNav}
        {...toolSelectionProps}
      />
      <div className='h-[--nav-header-height]'></div>
    </>
  );
}