import { Response } from "../api/response";
import { resolveAPIBase } from "../api/settings";
import { AppSettings } from "./models";

export class AppAPI {
  private apiBase: string;

  constructor() {
    this.apiBase = resolveAPIBase("app", "v1");
  }

  async settings(): Promise<Response<AppSettings>> {
    try {
      const httpResp = await fetch(`${this.apiBase}/settings`);
      const resp = await httpResp.json();
      return resp;
    } catch (e) {
      console.error("Failed to parse response", e);

      return {
        error: {
          message: `Failed to parse response`,
        },
      };
    }
  }
}
