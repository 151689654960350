import { IconCircleArrowRight, IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import classNames from "classnames";
import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import { LoadedImage } from "./atom";

function csScreenshotFrame(...args: classNames.ArgumentArray) {
  return classNames(
    `w-[var(--screenshot-frame-width)]`,
    `min-w-[var(--screenshot-frame-width)]`,
    `h-[var(--screenshot-frame-height)]`,
    `min-h-[var(--screenshot-frame-height)]`,
    ...args
  );
}

export type SourceScreenshotProps = {
  image: LoadedImage;
  parentRef: RefObject<HTMLDivElement>;

  onClick: () => void;
  onRemove: () => void;
}

export default function SourceScreenshot(props: SourceScreenshotProps) {
  const { image, parentRef, onClick, onRemove } = props;
  const [ref, active] = useIntersectionObserver({
    root: parentRef,
    rootMargin: '0px',
    threshold: 0.5,
  });

  const csCaption = classNames(
    'flex gap-[4px] text-[12px] items-center',
    'font-regular truncate',
    {
      'text-primary-content': active,
      'text-secondary-content': !active,
    }
  )
  const csCaptionIcon = classNames(
    {
      'text-accent': active,
      'text-secondary-content': !active,
    }
  )
  const csDeleteButton = classNames(
    "btn btn-ghost m-0 p-0 min-h-[20px] h-[20px] absolute top-[-8px] right-[-6px]",
    {
      'opacity-0': !active,
      'opacity-100': active
    }
  )

  return (
    <div className="" onClick={onClick} ref={ref}>
      <button className="p-0 px-[8px] btn btn-ghost no-animation outline-none">
        <h3 className={csCaption}>
          {image.caption ?? 'details'}
          <IconCircleArrowRight
            size='12px'
            className={csCaptionIcon}
          />
        </h3>
      </button>
      <div className={csScreenshotFrame('relative flex items-start')}>
        <button className={csDeleteButton} onClick={() => onRemove()}>
          <IconCircleMinus
            fill="var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)))"
            size="20px"
          />
        </button>
        <img className="w-full rounded-[8px]" src={image.base64Data} alt="foobar" />
      </div>
    </div>
  );
}

interface IntersectionOptions extends Omit<IntersectionObserverInit, 'root'> {
  root?: RefObject<Element> | null;
}

export function useIntersectionObserver(options: IntersectionOptions): [RefObject<HTMLDivElement>, boolean] {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let observedRef: HTMLDivElement | null = null;

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, {
      ...options,
      root: options.root ? options.root.current : null
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
      observedRef = targetRef.current;
    }

    return () => {
      if (observedRef) {
        observer.unobserve(observedRef);
      }
    };
  }, [options]);

  return [targetRef, isIntersecting];
}

export type SourceScreenshotAddProps = {
  onAddImage: (image: LoadedImage) => void;
  enabled: boolean;
}

export function SourceScreenshotAdd(props: SourceScreenshotAddProps) {
  const { onAddImage, enabled } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) {
        return;
      }

      const base64Data = (reader.result as string);

      onAddImage({
        name: file.name,
        base64Data: base64Data,
        mime: file.type,
      });
    };
    reader.readAsDataURL(file);
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (!file) {
        continue;
      }
      readFile(file);
    }
  };

  return (
    <div className="" onClick={() => {
      if (!enabled) {
        return false;
      }
      fileInputRef.current?.click();
    }}>
      <button className="p-0 px-[8px] btn btn-ghost no-animation outline-none">
        <h3 className="text-[12px] font-regular text-secondary-content">
          add more...
        </h3>
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="image/*"
        onChange={handleImageUpload}
      />
      <div className={csScreenshotFrame('flex items-center rounded-[8px] border-2 border-neutral')}>
        <IconCirclePlus
          size="24px"
          className="text-neutral align-middle mx-auto"
        />
      </div>
    </div>
  );
}