import { OAuthResponse, SupabaseClient, User } from "@supabase/supabase-js";
import { createContext, useContext, PropsWithChildren, useState, useEffect } from "react";
import { Session } from '@supabase/supabase-js'

function Loading() {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="spinner" />
    </div>
  );
}

export type Auth = {
  session: Session | null;
  user: User | null;
  signOut: () => void;
  signIn: {
    google: () => Promise<OAuthResponse>;
  };
};

const AuthContext = createContext<Auth>({
  session: null,
  user: null,
  signOut: () => { },
  signIn: {
    google: async () => {
      throw new Error('AuthProvider not ready');
    },
  },
});

export const useAuth = () => {
  return useContext(AuthContext);
}

type AuthProviderProps = PropsWithChildren<{
  supabase: SupabaseClient;
}>;

export default function AuthProvider(props: AuthProviderProps) {
  const { children, supabase } = props;

  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  const loadedSession = (session: Session | null) => {
    setSession(session);
    setLoading(false);
  };

  const signOut = async () => {
    const rv = await supabase.auth.signOut();
    setSession(null);
    return rv;
  };

  const signIn = {
    google: async () => {
      return supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin,
        },
      });
    },
  };

  useEffect(() => {
    supabase.auth.getSession()
      .then(d => {
        loadedSession(d.data?.session || null);
      });

    const authStateChange = supabase.auth.onAuthStateChange((_event, session) => {
      loadedSession(session);
    })

    const subscription = authStateChange.data?.subscription;
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  }, [supabase]);

  const auth: Auth = {
    session,
    user: session?.user || null,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={auth}>
      {loading && <Loading />}
      {!loading && children}
    </AuthContext.Provider>
  )
}