import { Navigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';

export default function Auth() {
  const auth = useAuth();

  if (auth.user) {
    return <Navigate to="/" />;
  }

  return (
    <div className='mt-4 flex flex-col items-center'>
      <div className="flex-1">
        <div className="btn btn-wide btn-info"
          onClick={auth.signIn.google}
        >
          Continue with Google
        </div>
      </div>
    </div>
  );
}
