import { Response } from "../api/response";
import { AppAPI } from "./api";
import { AppSettings } from "./models";

export type LoadedAppSettings = {
  appSettings: AppSettings;
};

export async function loadAppSettings(): Promise<LoadedAppSettings> {
  const apiClient = new AppAPI();

  let resp: Response<AppSettings>;
  try {
    resp = await apiClient.settings();
  } catch (e) {
    console.error("Failed to request app settings", e);
    throw new Error("Failed to request app settings");
  }

  if ("data" in resp) {
    console.log("Loaded app settings", resp.data);
    return { appSettings: resp.data };
  }

  let errorDetails = "Unknown response";
  if ("error" in resp) {
    console.error("Failed to load app settings", resp.error.message);
    errorDetails = resp.error.message;
  }
  throw new Error(errorDetails);
}
