import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../components/AuthProvider";

export default function AuthRequired() {
  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/auth" />;
  }
  return <Outlet />
}