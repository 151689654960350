import classNames from "classnames";

export function csSectionTitle(...args: classNames.ArgumentArray): string {
  return classNames(
    "font-semibold",
    "text-primary-content",
    "text-[16px]",
    ...args
  );
}
