import { redirect } from "react-router-dom";
import { loadAppSettings, LoadedAppSettings } from "../../app/loader";
import { Tool } from "../../app/models";

export type FlowScreenshotData = LoadedAppSettings & {
  selectedTool: Tool;
};

export type FlowScreenshotParams = {
  toolId: string;
};

async function flowScreenshotLoader(props: {
  params: FlowScreenshotParams;
}): Promise<unknown> {
  const {
    params: { toolId },
  } = props;

  const { appSettings } = await loadAppSettings();
  if (appSettings.tools.length < 1) {
    throw new Error("No tools available");
  }

  let selectedTool = appSettings.tools.find((t) => t.id === toolId);
  if (!selectedTool) {
    return redirect("/tools/" + appSettings.tools[0].id);
  }

  return {
    appSettings,
    selectedTool,
  };
}

export default flowScreenshotLoader;
