import { useEffect } from "react";

export type ToastMessage = {
  id: string;
  content: string;
  durationInMilliseconds: number;
};

export type RemoveToast = (m: ToastMessage) => void;

type ToastProps = {
  message: ToastMessage;
  removeToast: RemoveToast;
};

function Toast(props: ToastProps) {
  const { message, removeToast } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message);
    }, message.durationInMilliseconds);

    return () => {
      clearTimeout(timer);
    };
  }, [message, removeToast]);

  return (
    <div className="toast toast-top toast-center z-[--z-index-toast]">
      <div role="alert" className="alert m-0 p-2 bg-primary text-xs text-primary-content">
        {message.content}
      </div>
    </div>
  );
}

export type ToastsProps = {
  messages: ToastMessage[];
  removeToast: RemoveToast;
};

export default function Toasts(props: ToastsProps) {
  const { messages, removeToast } = props;

  return (
    <div>
      {messages.map(m => (
        <Toast key={m.id} message={m} removeToast={removeToast} />
      ))}
    </div>
  );
}