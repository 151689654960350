import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { atom, useAtom, useAtomValue } from "jotai";
import { LoadedImage } from "../atom";
import { Buffer } from "buffer";

// init -> ffmpegReady -> fileParsing -> fileParsed
type State =
  | "init"
  | "ffmpegLoading"
  | "ffmpegReady"
  | "fileParsing"
  | "fileParsed"
  | "error";

export type ComponentState = {
  // state
  state: State;

  // state data
  ffmpeg: FFmpeg | null;
  errorDetails: string | null;
  inputFile: File | null;
  frameImages: LoadedImage[];
};

function defaultComponentState(): ComponentState {
  return {
    state: "init",

    ffmpeg: null,
    errorDetails: null,
    inputFile: null,
    frameImages: [],
  };
}

const componentStateAtom = atom<ComponentState>(defaultComponentState());

export function useComponentStateValue() {
  return useAtomValue(componentStateAtom);
}

export function useComponentStateOp() {
  const [componentState, setComponentState] = useAtom(componentStateAtom);

  return {
    tick() {
      if (componentState.state === "init") {
        return this.initFFMPEG();
      }
    },

    initFFMPEG: async () => {
      if (componentState.state !== "init") {
        return;
      }

      if (componentState.ffmpeg !== null) {
        return;
      }

      const ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        console.log("ffmpeg log", message);
      });

      setComponentState((state) => ({
        ...state,
        state: "ffmpegLoading",
      }));

      // TODO: copy to public folder
      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";

      try {
        // toBlobURL is used to bypass CORS issue, urls with the same
        // domain can be used directly.
        await ffmpeg.load({
          coreURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.js`,
            "text/javascript"
          ),
          wasmURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.wasm`,
            "application/wasm"
          ),
        });
      } catch (e) {
        console.error("load ffmpeg", e);

        setComponentState((state) => ({
          ...state,
          state: "error",
          errorDetails: "Load external libraries failed",
        }));
        return;
      }

      setComponentState((state) => ({
        ...state,
        state: "ffmpegReady",
        ffmpeg,
      }));
    },

    processVideo: async (file: File) => {
      if (
        componentState.state !== "ffmpegReady" &&
        componentState.state !== "fileParsed"
      ) {
        return;
      }

      const ffmpeg = componentState.ffmpeg;
      if (!ffmpeg) {
        console.error("ffmpeg is null");
        return;
      }

      setComponentState((state) => ({
        ...state,
        state: "fileParsing",
        inputFile: file,
      }));

      const dir = Math.random().toString(36).substring(7);

      try {
        await ffmpeg.writeFile("input.mp4", await fetchFile(file));
        await ffmpeg.createDir(dir);
        await ffmpeg.exec([
          "-i",
          "input.mp4",
          "-vf",
          "select=eq(pict_type\\,I)",
          "-vsync",
          "vfr",
          `${dir}/keyframe_%04d.png`,
        ]);
      } catch (e) {
        console.error("process video", e);

        setComponentState((state) => ({
          ...state,
          state: "error",
          errorDetails: "Process video failed",
        }));
        return;
      }

      const files = await ffmpeg.listDir(dir);
      const images: LoadedImage[] = [];
      for (const f of files) {
        if (!f.name.endsWith(".png")) {
          continue;
        }

        const filePath = `${dir}/${f.name}`;
        const fileData = await ffmpeg.readFile(`${dir}/${f.name}`);
        console.log(`loaded ${filePath}`);

        const data = Buffer.from(fileData).toString("base64");

        images.push({
          name: f.name,
          base64Data: `data:image/png;base64,${data}`,
          mime: "image/png",
        });
      }

      setComponentState((state) => ({
        ...state,
        state: "fileParsed",
        frameImages: images,
      }));

      return images;
    },
  };
}
