export function resolveAPIBase(...ps: Array<string>) {
  let base = `http://127.0.0.1:8081/`;
  if (process.env.REACT_APP_API_BASE) {
    base = process.env.REACT_APP_API_BASE;
  }
  if (base.endsWith("/")) {
    base = base.slice(0, -1);
  }

  return `${base}/${ps.join("/")}`;
}

export function resolvePagesAPIbase(...ps: Array<string>) {
  let base = `http://127.0.0.1:8788/`;
  if (process.env.REACT_APP_PAGES_API_BASE) {
    base = process.env.REACT_APP_PAGES_API_BASE;
  }
  if (base.endsWith("/")) {
    base = base.slice(0, -1);
  }

  return `${base}/${ps.join("/")}`;
}
